import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsmobile from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import MainLayout from "./components/MainLayout";
import MainScreen from "./components/MainScreen";
import OutputPortfolio from "./components/OutputPortfolio";
import OutputIndividual from "./components/OutputIndividual";
import CalculateRiskMain from "./components/CalculateRiskMain";
import Monitor from "./components/Monitor";
import Advisory from "./components/Advisory";
import LandingPage from "./components/LandingPage";
Amplify.configure(awsmobile);

function App() {
const [outputportfolioscreen, setoutputportfolioscreen] = useState(false);
const [riskscreen, setriskscreen] = useState(false);
const [landingscreen, setlandingscreen] = useState(true);
const [mainscreen, setMainscreen] = useState(false);
const [monitorscreen, setmonitorscreen] = useState(false);
const [advisorycreen, setadvisorycreen] = useState(false);
const [calulateriskmainscreen, setcalulateriskmainscreen] = useState(false);
// const [email, setEmail] = useState('');
  return (

<div className="App" >
{landingscreen && 
  <LandingPage outputportfolioscreen={outputportfolioscreen}
   setoutputportfolioscreen={setoutputportfolioscreen}
   setlandingscreen={setlandingscreen}/>
}
  {outputportfolioscreen && 
    <OutputPortfolio
     mainscreen={mainscreen}
     setMainscreen={setMainscreen}
     setoutputportfolioscreen={setoutputportfolioscreen}
     setcalulateriskmainscreen = {setcalulateriskmainscreen}
     calulateriskmainscreen={calulateriskmainscreen}
     setmonitorscreen={setmonitorscreen}
     advisorycreen={advisorycreen}
     setadvisorycreen={setadvisorycreen}
     />
}
{monitorscreen && 
    <Monitor
      setmonitorscreen={setmonitorscreen}
      setMainscreen={setMainscreen}
      riskscreen={riskscreen}
      setriskscreen={setriskscreen}
      setcalulateriskmainscreen={setcalulateriskmainscreen}
      setoutputportfolioscreen={setoutputportfolioscreen}
      advisorycreen={advisorycreen}
      setadvisorycreen={setadvisorycreen}
     />}
{mainscreen && 
    <MainScreen
    setmonitorscreen={setmonitorscreen}
    setcalulateriskmainscreen={setcalulateriskmainscreen}
      setMainscreen={setMainscreen}
      riskscreen={riskscreen}
      setriskscreen={setriskscreen}
      setoutputportfolioscreen={setoutputportfolioscreen}
      advisorycreen={advisorycreen}
      setadvisorycreen={setadvisorycreen}
     />}
     {calulateriskmainscreen && 
    <CalculateRiskMain
    setmonitorscreen={setmonitorscreen}
    setcalulateriskmainscreen={setcalulateriskmainscreen}
      setMainscreen={setMainscreen}
      riskscreen={riskscreen}
      setriskscreen={setriskscreen}
      setoutputportfolioscreen={setoutputportfolioscreen}
      advisorycreen={advisorycreen}
      setadvisorycreen={setadvisorycreen}
     />}
    {riskscreen && 
    <OutputIndividual 
    setcalulateriskmainscreen={setcalulateriskmainscreen}
    mainscreen={mainscreen}
    setmonitorscreen={setmonitorscreen}
    setMainscreen={setMainscreen}
    setriskscreen={setriskscreen}
    setoutputportfolioscreen={setoutputportfolioscreen}
    advisorycreen={advisorycreen}
    setadvisorycreen={setadvisorycreen}/>
    }
     {advisorycreen && 
    <Advisory 
    setcalulateriskmainscreen={setcalulateriskmainscreen}
    mainscreen={mainscreen}
    setmonitorscreen={setmonitorscreen}
    setMainscreen={setMainscreen}
    setriskscreen={setriskscreen}
    setoutputportfolioscreen={setoutputportfolioscreen}
    advisorycreen={advisorycreen}
    setadvisorycreen={setadvisorycreen}/>
    }
</div>
  );
}
export default App;
//export default withAuthenticator(App);