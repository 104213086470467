import { getArea } from "ol/sphere";
import { Style, Stroke, Fill, Text } from "ol/style";

export const getLayerByName = (map, layerName) => {
  if (!map) return;
  var layers = map.getLayers().getArray();

  for (var i = 0; i < layers.length; i++) {
    if (layers[i].get("name") === layerName) {
      return layers[i];
    }
  }
};

export const removeLayerByName = (map, layerName) => {
  const layer = getLayerByName(map, layerName);
  map.removeLayer(layer);
};

export const calculateArea = function (polygon) {
  const cloned = polygon.clone();
  cloned.transform("EPSG:4326", "EPSG:3857");
  const area = getArea(cloned) / 10000;
  return area;
};

export const getAvgColor = (feature, selectedState) => {

  
    const style = new Style({
      stroke: new Stroke({
        color: "black",
        width: 1,
      }),
      text: new Text({
        font: "bold 14px",
        /*text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want*/
          text: feature.getProperties().NAME_3 ? feature.getProperties().NAME_3 :feature.getProperties().NAME_2?feature.getProperties().NAME_2:feature.getProperties().NAME_1?feature.getProperties().NAME_1:'',

        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "black",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: "#fff",
      }),
    });
    return style;
};



export const getFloodColor = (feature, selectedState, select) => {
  let flood_index = feature.getProperties().flood_index;
    const style = new Style({
      stroke: new Stroke({
        color: "black",
        width: select ? 5 : 1,
      }),
      text: new Text({
        font: "bold 14px",
        /*text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want*/
          text: feature.getProperties().NAME_3 ? feature.getProperties().NAME_3 :feature.getProperties().NAME_2?feature.getProperties().NAME_2:feature.getProperties().NAME_1?feature.getProperties().NAME_1:'',

        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "black",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: "#fff",
      }),
    });
    return style;
  
};

export const getSpiColor = (feature, selectedState, select) => {
  let spi_index = feature.getProperties().spi_index;
  
    const style = new Style({
      stroke: new Stroke({
        color: "black",
        width: select ? 5 : 1,
      }),
      text: new Text({
        font: "bold 14px",
        /*text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want*/
          text: feature.getProperties().NAME_3 ? feature.getProperties().NAME_3 :feature.getProperties().NAME_2?feature.getProperties().NAME_2:feature.getProperties().NAME_1?feature.getProperties().NAME_1:'',
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "black",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: "#fff",
      }),
    });
    return style;
};

export const getTransparentColor = (feature,selectedState, select) => {  
    const style = new Style({
      stroke: new Stroke({
        color: "black",
        width: select ? 5 : 1,
      }),
      text: new Text({
        font: "bold 14px",
        /*text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want*/
          text: feature.getProperties().NAME_3 ? feature.getProperties().NAME_3 :feature.getProperties().NAME_2?feature.getProperties().NAME_2:feature.getProperties().NAME_1?feature.getProperties().NAME_1:'',
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "black",
          width: 1,
        }),
      }),
      fill: new Fill({
        color:"rgba(255, 255, 255, 0.1)",
      }),
    });
    return style;
};

