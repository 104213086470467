"use client";
import { useEffect } from "react";
import OLVectorLayer from "ol/layer/Vector";
import { useMap } from "../../hooks/useMap";
import { useSelector } from "react-redux";

const VectorLayer = ({
  source,
  style,
  zoomTo = false,
  zIndex = 0,
  name = "",
  data = null,
}) => {
  const { map } = useMap();
  const countryName = useSelector((state) => state.countryName);
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const level4Data = useSelector((state) => state.level4Data);
  const level3Data = useSelector((state) => state.level3Data);


  useEffect(() => {
    if (!map) return;

    let vectorLayer = new OLVectorLayer({
      source,
      style,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    if (zoomTo)
      map.getView().fit(source.getExtent(), {
        padding: [50, 50, 50, 50],
        duration: 1000,
      });

    if (name) vectorLayer.set("name", name);

    return () => {
      if (map) map.removeLayer(vectorLayer);
    };
  }, [map, data]);

  useEffect(() => {
    if (zoomTo)
    map.getView().fit(source.getExtent(), {
      padding: [50, 50, 50, 50],
      duration: 1000,
    });    
  }, [countryName, stateName, districtName, level4Data, level3Data, data])


  return <></>;
};

export default VectorLayer;
