import React, { useEffect, useState } from "react";
import DetailedInfo from "./DetailedInfo";
import LandingPageUsers from "./LandingPageUsers";

const LandingPage = (props) => {
  const [activeTab, setActiveTab] = useState("#home");
  const handleClick = (hash) => {
    setActiveTab(hash);
    window.history.pushState(null, null, hash);
  };

  const handlebuttonClick = () => {
    props.setoutputportfolioscreen(true);
    props.setlandingscreen(false);
    setActiveTab("");
    window.history.pushState(null, null, "/");
  };
  const containerStyle = {
    backgroundImage:
      "url(//custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1500,w_2000,f_auto,q_auto/346142/563342_368106.jpg)",
    height: "700px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    color: "black",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    paddingTop: "70px",
  };

  const textContainerStyle = {
    position: "relative",
    left: "90px",
    padding: "30px",
    borderRadius: "10px",
  };

  const titleStyle = {
    fontSize: "48px",
    fontWeight: "500",
  };

  const subTitleStyle = {
    fontSize: "24px",
    fontWeight: "normal",
    margin: "5px 0",
  };

  const highlightStyle = {
    fontSize: "55px",
    fontWeight: "700",
  };

  const subTextStyle = {
    fontSize: "25px",
    marginTop: "20px",
  };

  const buttonStyle = {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "18px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    borderRadius: "25px",
    cursor: "pointer",
  };

  const topbuttonStyle = {
    fontSize: "16px",
    padding: "8px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    borderRadius: "25px",
    cursor: "pointer",
  };
  const navStyle = {
    display: "flex",
    justifyContent: "flex-end", // Align items to the right
    position: "sticky", // Make the navbar sticky
    top: 0, // Stick to the top
    right: 0, // Align to the right side
    padding: "5px",
    backgroundColor: "#91b187", // Set header background color
    borderBottom: "1px solid #ccc",
    zIndex: 1000, // Ensure it stays above other content
  };

  const navLinkStyle = (isActive) => ({
    marginLeft: "20px",
    fontSize: "16px",
    color: "white",
    textDecoration: "none",
    padding: "10px",
    backgroundColor: isActive ? "orange" : "", // Background color on active
    transition: "background-color 0.3s ease", // Smooth transition for background color
  });

  const navLinkHoverStyle = {
    backgroundColor: "orange", // Background color on hover
    padding: "20px 40px",
    color: "white", // Text color on hover for better visibility
  };

  const sectionStyle = {
    padding: "50px 20px",
    textAlign: "center",
  };
  const userStyle = {
    textAlign: "center",
    padding: "0px",
  };
  const sectionTitleStyle = {
    fontSize: "32px",
    fontWeight: "bold",
    marginBottom: "20px",
  };

  const sectionTextStyle = {
    fontSize: "18px",
    marginBottom: "20px",
  };

  const footerStyle = {
    padding: "20px",
    textAlign: "center",
    backgroundColor: "black",
    color: "white",
  };

  return (
    <div style={{ fontStyle: "normal" }}>
      <nav style={navStyle}>
        <div>
          <a
            href="#home"
            style={navLinkStyle(activeTab === "#home")}
            onClick={() => handleClick("#home")}
          >
            Home
          </a>
          {/* <a
            href="#tara"
            style={navLinkStyle(activeTab === "#tara")}
            onClick={() => handleClick("#tara")}
          >
            Tara
          </a> */}
          <a
            href="#about"
            style={navLinkStyle(activeTab === "#about")}
            onClick={() => handleClick("#about")}
          >
            About
          </a>
          <a
            href="#users"
            style={navLinkStyle(activeTab === "#users")}
            onClick={() => handleClick("#users")}
          >
            Users
          </a>

          <a
            href="#contact"
            style={navLinkStyle(activeTab === "#contact")}
            onClick={() => handleClick("#contact")}
          >
            Contact
          </a>
          <button style={topbuttonStyle} onClick={handlebuttonClick}>
            ACCESS TARA
          </button>
        </div>
      </nav>
      <div style={containerStyle}>
        <div style={{ color: "black" }} id="home">
          <div style={textContainerStyle}>
            <div>
              <span style={highlightStyle}>T</span>
              <span style={titleStyle}>ool for</span>
            </div>
            <div>
              <span style={highlightStyle}>A</span>
              <span style={titleStyle}>gricultural</span>
            </div>
            <div>
              <span style={highlightStyle}>R</span>
              <span style={titleStyle}>isk</span>
            </div>
            <div>
              <span style={highlightStyle}>A</span>
              <span style={titleStyle}>ssessment & Advice</span>
            </div>
            <div style={subTextStyle}>
              Improve global food security with
              <br />
              satellites, through blended
              <br />
              financial solutions for farmers
            </div>
            <button style={buttonStyle} onClick={handlebuttonClick}>
              ACCESS TARA
            </button>
          </div>
        </div>
      </div>
      {/* <section style={sectionStyle} id="tara">
        <h2 style={sectionTitleStyle}>TARA</h2>
       

       <DetailedInfo style={{ textAlign:'left'
       }}/>
      </section> */}
      <section
        style={{ padding: "40px", backgroundColor: "#f9f9f9" }}
        id="about"
      >
        <h2
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          About{" "}
        </h2>
        <p style={{ fontSize: "24px", marginBottom: "20px" }}>
          TARA is an AI-driven risk assessment platform utilizing historical
          climate data and crop requirements to generate location-specific
          climate risk scores, allowing financial institutions to assess the
          feasibility of lending to farmers for specific crop value chains in
          particular regions and seasons.​
        </p>
        <h3>De-Risking Loans:</h3>
        The platform enables financial institutions to mitigate lending risks by
        providing insights into the suitability of crops for specific areas,
        thus allowing them to make informed decisions about lending without
        additional financial tools or offering crop insurance.​
        <h3>Advisory Support System:</h3>
        TARA also acts as an advisory tool for smallholder farmers, offering
        guidance on crop suitability based on water and climatic triggers, and
        providing agronomic advice for optimal planting practices, thereby
        reducing production risks and enhancing food security in Kenya and
        Sub-Saharan Africa.
      </section>
      <section style={userStyle} id="users">
        <LandingPageUsers />
      </section>

      <section
        style={{
          backgroundColor: "#f0f0f0",
          padding: "20px",
          borderRadius: "8px",
          textAlign: "center",
        }}
        id="contact"
      >
        <h2
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}
        >
          CONTACT
        </h2>
        <p
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          If you have anything to ask or share, please contact us and we will
          get back to you as soon as possible!
        </p>
        <form style={{ maxWidth: "500px", margin: "0 auto" }}>
          <div style={{ marginBottom: "10px" }}>
            <label
              htmlFor="name"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              required
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label
              htmlFor="email"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              required
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="message"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            style={{
              backgroundColor: "#000",
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </form>
      </section>

      <footer style={footerStyle}>© 2024 powered by Agtuall</footer>
    </div>
  );
};

export default LandingPage;
