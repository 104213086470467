import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more"; // Import the highcharts-more module

// Initialize Highcharts with the highcharts-more module
HighchartsMore(Highcharts);

const RainfallAreaRangeGraph = ({ data }) => {
  // Define the configuration for the Highcharts graph
  const options = {
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      title: {
        text: "Month",
      },
    },
    yAxis: {
      title: {
        text: "Rainfall (mm)",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Rainfall Range",
        type: "arearange",
        data: data,
        lineWidth: 1,
        fillOpacity: 0.3,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
export default RainfallAreaRangeGraph;
