import React from 'react';
import '../landingpage.css'; // Add your CSS file if needed

const LandingPageUsers = () => {
  const sections = [
    {
      title: 'Farmer',
      subtitle: 'Improved access to finance with tailored products',
      description: 'TARA decreases the vulnerability of smallholders to climate extremes through agricultural advice and tailored loans and insurances',
      imageSrc: '//custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1440,w_720,f_auto,q_auto/346142/870774_765495.png'
    },
    {
      title: 'Bank/Lender',
      subtitle: 'Improved knowledge on climate related non-payment',
      description: 'TARA enables banks and lenders to link non-payment among farmers to historical climate risk and develop small-scale solutions',
      imageSrc: '//custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1440,w_720,f_auto,q_auto/346142/459944_611861.png'
    },
    {
      title: 'Insurer',
      subtitle: 'Decreased basis risk for smallholders insurance',
      description: 'TARA provides up to 17 years of consistent climate data at field scale which enable insurers to provide state-of-the-art products',
      imageSrc: '//custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1440,w_720,f_auto,q_auto/346142/347617_436210.png'
    },
    {
      title: 'NGOs',
      subtitle: 'Actionable knowledge on vulnerability of farmers',
      description: 'TARA shows which farmers need what kind of help to increase and stabilize their agricultural production',
      imageSrc: '//custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1440,w_720,f_auto,q_auto/346142/75753_895599.png'
    }
  ];

  return (
    <div className="transition lazyloaded s-bg-image s-bg-blurred s-bg-light-text s-bg-overlay s-new-media-section s-section s-columns-section _animate-background background-image"
         style={{
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           backgroundColor: 'transparent',
           backgroundPosition: '50% 50%',
           backgroundImage: 'url("//custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1500,w_2000,f_auto,q_auto/346142/375918_858920.jpeg")',
           backgroundAttachment: 'fixed'

         }}>
      <div className="blurred-layer loaded css-1tnf9eu css-1bm5zrh0"></div>
      <div className="container" style={{padding:'30px'}}>
        <div className="columns sixteen">
          <div className="s-title-group">
            <div className="s-title">
              <div className="s-component s-text s-animation-page-slide_in">
                <div className="s-component-content s-font-title" style={{ whiteSpace: 'pre-wrap' }}>
                  <h2>
                    <p className="s-text-color-custom1">USERS</p>
                  </h2>
                </div>
              </div>
            </div>
            <div className="s-subtitle">
              <div className="s-component s-text s-animation-page-slide_in">
                <div className="s-component-content s-font-heading" style={{ whiteSpace: 'pre-wrap' }}>
                  <h4>
                    <p>What does TARA mean for the different users?</p>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="s-mh s-repeatable">
            <div className="s-repeatable-items">
              {sections.map((section, index) => (
                <div key={index} className="s-repeatable-item">
                  <div className="s-item-media-wrapper s-mhi">
                    <div className="s-item-media-group">
                      <div className="s-component s-media s-animation-page-slide_in">
                        <div className="s-component-content">
                          <div className="s-img-wrapper">
                            <img
                              alt={section.title}
                              className="crop-default lazyloaded"
                              width="260"
                              height="240"
                              src={section.imageSrc}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="s-item-text-group s-animation-page-slide_in">
                    <div className="s-item-title">
                      <div className="s-component s-text">
                        <div className="s-component-content s-font-heading">
                          <h3><p>{section.title}</p></h3>
                        </div>
                      </div>
                    </div>
                    <div className="s-item-subtitle">
                      <div className="s-component s-text">
                        <div className="s-component-content s-font-body">
                          <h6><p><strong>{section.subtitle}</strong></p></h6>
                        </div>
                      </div>
                    </div>
                    <div className="s-item-text">
                      <div className="s-component s-text">
                        <div className="s-component-content s-font-body">
                          <p>{section.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageUsers;
