import React, { useState, useEffect } from "react";
import "../styles.css";
//import { Auth } from "aws-amplify";
import { NdviGraph } from "./NdviGraph";
import {SSMGraph} from "./SSMGraph";
import { useDispatch, useSelector } from "react-redux";
import { changeModisNDVILayer, changeSentinel2NDVILayer, changeshowModisNDVIDot, changeshowSentinelNDVIDot,changeVolumetricSSMLayer } from '../redux/action';
import CircularProgress from '@mui/material/CircularProgress';
import GeoJSON from 'ol/format/GeoJSON';
import TileLayer from "ol/layer/Tile";
import XYZ from 'ol/source/XYZ.js';
import { useMap } from './hooks/useMap';
import { TbChartDonut } from "react-icons/tb";



const MapDrawer = (props) => {
  const level1Data = useSelector((state) => state.level1Data);
  const level2Data = useSelector((state) => state.level2Data);
  const level3Data = useSelector((state) => state.level3Data);
  const [isExpanded, setIsExpanded] = useState(false);
  const [modisNDVI, setmodisNDVI] = useState([]);
  const [sentinal2NDVIgraph, setsentinal2NDVIgraph] = useState([]);
  const [volumetricSSM, setVolumetricSSM] = useState([]);
  const [modisloading, setmodisLoading] = useState(false);
  const [volumetricSSMloading, setVolumetricSSMLoading] = useState(false);
  const [sentinal2Loading, setsentinal2Loading] = useState(false);
  const modisNdviLayer = useSelector((state) => state.modisNdviLayer);
  const sentinel2NdviLayer = useSelector((state) => state.sentinel2NdviLayer);
  const volumetricSSMLayer = useSelector((state) => state.volumetricSSMLayer);
  const showModisNDVIDot = useSelector((state) => state.showModisNDVIDot);
  const showSentinel2NDVIDot = useSelector((state) => state.showSentinel2NDVIDot);
  const [ndvilayeroverlayLoading, setndvilayeroverlayLoading] = useState(false);
  const {map } = useMap();
  const dispatch = useDispatch();
  const startDateSelected = useSelector((state) => state.startDateSelected);
  const endDateSelected = useSelector((state) => state.endDateSelected);




  
  useEffect(() => {
    let url5 = `/api/homepage/modisNDVI?country=Kenya&county=${level1Data}&constituency=${level2Data}&ward=${level3Data}&start_date=${startDateSelected}&end_date=${endDateSelected}`;

    if (props.modis5DayIntervalNDVIGraph && level1Data !== "" && level2Data !== "" && level3Data !== "") {
      setmodisLoading(true);
      fetch(url5, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          setmodisNDVI(await data);
          setmodisLoading(false);

        })
        .catch((error) => {
          setmodisLoading(false);
          console.error("Error fetching data:", error);
        });
    }
  }, [level1Data,level2Data,level3Data, props.modis5DayIntervalNDVIGraph,startDateSelected,endDateSelected]);

 
 
 
 
  
  useEffect(() => {
    let url14 = `/api/homepage/sentinel2NDVI?country=Kenya&county=${level1Data}&constituency=${level2Data}&ward=${level3Data}&start_date=${startDateSelected}&end_date=${endDateSelected}`;

    if (props.sentinal2NDVI && level1Data !== "" && level2Data !== "" && level3Data !== "") {
      setsentinal2Loading(false);
      fetch(url14, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          setsentinal2NDVIgraph(await data);
          setsentinal2Loading(false);
        })
        .catch((error) => {
          setsentinal2Loading(false);
          console.error("Error fetching data:", error);
        })

    }
  }, [level1Data,level2Data,level3Data, props.sentinal2NDVI,startDateSelected,endDateSelected]);


  useEffect(() => {
    let url5 = `/api/homepage/volumetricSSM?country=Kenya&county=${level1Data}&constituency=${level2Data}&ward=${level3Data}&start_date=${startDateSelected}&end_date=${endDateSelected}`;

    if (level1Data !== "" && level2Data !== "" && level3Data !== "") {
      setmodisLoading(true);
      fetch(url5, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          setVolumetricSSM(await data);
          setVolumetricSSMLoading(false);

        })
        .catch((error) => {
          setVolumetricSSMLoading(false);
          console.error("Error fetching data:", error);
        });
    }
  }, [level1Data,level2Data,level3Data,props.volumetricSSM,startDateSelected,endDateSelected]);

  const handleDatasetDownloadbutton = (dataset) => {
    async function genToken() {
      fetch(
        "/api/homepage/exploreDatasetDownload?country=Kenya"+
        "&dataset=" +
        dataset +
        "&level1=" +
        level1Data +
        "&level2=" +
        level2Data +
        "&level3=" +
        level3Data ,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: jwtToken,
          },
        })

        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `CDL_${dataset}.zip`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'application/zip' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);

          // Set loading state if needed
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }

    genToken();
  };

  const formatDate = d => new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())).toISOString().slice(0,10);

	const getLayersArray = async (data, days, endDate, layerName) => {
		let res = [];
		let index = 1;
		let maxAttempts = 5; // Maximum number of attempts before giving up
		let baseDelayMs = 1000; // Initial delay in milliseconds
    const execute = async () => {
      let startDate = new Date();
      let temp = startDate.getDate();
      startDate.setDate(temp - days * index);
      startDate = formatDate(startDate); // Format startDate
  
      // Set loading state to true
      setndvilayeroverlayLoading(true);
  
      const geojsonData = {
          type: 'FeatureCollection',
          features: [
              {
                  type: 'Feature',
                  properties: {},
                  geometry: data,
              },
          ],
      };
  
      let body = JSON.stringify(geojsonData);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body,
      };
  
      try {
          const response = await fetch(
              `/api/tiles/tile_generation?start_date=${startDate}&end_date=${endDate}&layer_name=${layerName}`,
              requestOptions
          );
          const result = await response.json();
          res = [...result];
          // Set loading state to false
          setndvilayeroverlayLoading(false);
  
          if (result.length === 0) {
              const delayMs = baseDelayMs * 2 ** index;
              //console.log(`Retry attempt ${index} after ${delayMs}ms`);
              await new Promise((resolve) => setTimeout(resolve, delayMs));
              index++;
              return execute(); // Retry the operation
          }
          return res; // Return the result if successful
      } catch (error) {
          console.error(error);
          // Set loading state to false
          setndvilayeroverlayLoading(false);
  
          if (index >= maxAttempts) {
              throw error; // Throw the error if maximum attempts reached
          }
          const delayMs = baseDelayMs * 2 ** index;
          //console.log(`Retry attempt ${index} after ${delayMs}ms`);
          await new Promise((resolve) => setTimeout(resolve, delayMs));
          index++;
          return execute(); // Retry the operation
      }
  };
		return execute();
	};



  const expandDrawer = () => {
    setIsExpanded(!isExpanded);
  };

  const dataseticonStyle = {
    marginRight: '10px',
    height: '24px',
    width: '24px',
  };

  const showNDVILayers = (layersArray, layerPrefix) => {
    layersArray.forEach((layer, index) => {
      let source = new XYZ({
        url: layer,
        crossOrigin: 'anonymous',
      })

      let layer1 = new TileLayer({
        source,
        name: layerPrefix + index,
        zIndex: 1
      });
      map.addLayer(layer1);
    });
  }



  const handleModisNdviIconClick = async () => {
    // setShowModisNDVIDot(!showModisNDVIDot);
    dispatch(changeshowModisNDVIDot(!showModisNDVIDot));
    dispatch(changeModisNDVILayer(!modisNdviLayer));

    if (!showModisNDVIDot) {
      const administrativeBoundaryGeometry = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get('name') === 'AdministrativeBoundary')
        .getSource()
        .getFeatures()[0]
        .getGeometry();
      let geoJSONFormat = new GeoJSON();
      let geoJSON = geoJSONFormat.writeGeometry(administrativeBoundaryGeometry);
      geoJSON = JSON.parse(geoJSON);
      let endDate = new Date().toISOString().split('T')[0];
      const layersArray = await getLayersArray(geoJSON, 5, endDate, 'NDVI-Modis');
      showNDVILayers(layersArray, 'NDVIModis');

    } else {
      props.removeNDVILayers('NDVIModis');
    }

  };


  const handleSentinel2NdviIconClick = async () => {
    // setShowSentinel2NDVIDot(!showSentinel2NDVIDot);
    dispatch(changeshowSentinelNDVIDot(!showSentinel2NDVIDot));
    dispatch(changeSentinel2NDVILayer(!sentinel2NdviLayer));
    if (!showSentinel2NDVIDot) {
      const administrativeBoundaryGeometry = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get('name') === 'AdministrativeBoundary')
        .getSource()
        .getFeatures()[0]
        .getGeometry();
      let geoJSONFormat = new GeoJSON();
      let geoJSON = geoJSONFormat.writeGeometry(administrativeBoundaryGeometry);
      geoJSON = JSON.parse(geoJSON);
      let endDate = new Date().toISOString().split('T')[0];
      const layersArray = await getLayersArray(geoJSON, 5, endDate, 'NDVI-Sentinel');
      showNDVILayers(layersArray, 'NDVISentinel');
    } else {
      props.removeNDVILayers('NDVISentinel');
    }

  };


  return (
    <div className={`map-drawer-container ${props.isDrawerOpen ? 'shifted' : ''} ${isExpanded ? 'expanded' : ''}`} >
      <div className="toggledrawericon" onClick={props.toggleDrawer}>
        {/* <FiChevronsLeft style={iconStyle} /> */}
     {props.isDrawerOpen ? <TbChartDonut  size={21} color="white"/> : <TbChartDonut  size={21} color="white" />} 
      </div>
      <div className={`drawer-content ${props.isDrawerOpen ? 'visible' : ''} ${isExpanded ? 'expanded' : ''}`} >
        <div className="top-icons-container">
          <img src="expand-diagonal.png" alt="icon1" className="top-icon" onClick={expandDrawer} />
          <img src="chevron-2-right.png" onClick={() => props.setIsDrawerOpen(false)} alt="icon2" className="top-icon" />
        </div>
        <div className='maplayerspannel'>
          <div className="exploretext">Visualize  datasets</div>
          <div className="exploresecondarytext"> Select a single dataset to layer on the Map.</div>
          <hr className="horizontal-rule" />
          <div className='datasetstext'>Datasets</div>
          {
            props.tamsatDailyGraph ?
              <div>
                <div className='datasetgraph' style={{ display: 'flex', alignItems: 'center', padding: 16, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src="raindrop.png" alt="Raindrop" style={dataseticonStyle} />
                    <div style={{ top: '10px', flexDirection: 'column' }}>TAMSAT Daily Rainfall</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/*<MdGridOn style={{ height: '22px', width: '20px', marginRight: '8px', color: '#818589' }} onClick={handleFishnetClick} />*/}
                    <img src="layers.png" alt="Layer" style={{ height: '20px', width: '20px', marginRight: '8px' }} />
                    <img
                      src="download.png"
                      alt="Download"
                      style={{ height: '20px', width: '20px' }}
                      onClick={() => handleDatasetDownloadbutton("tamsatDaily")}
                    />
                  </div>
                </div>
              </div>
              :
              null}

              
          {
            props.sentinal2NDVI ?
              <div>
                <div className='datasetgraph' style={{ display: 'flex', alignItems: 'center', padding: 16, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src="plant.png" alt="Vegetation" style={dataseticonStyle} />
                    <div style={{ top: '10px', flexDirection: 'column' }}>SENTINEL-2 5 Day interval NDVI</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  {ndvilayeroverlayLoading ?  
                  <> <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)', position: 'relative', height: '32px', width: '32px', marginRight: '8px', padding: '5px'  }} /></>
                  : 
                  <div className="icononmap" style={{ position: 'relative', height: '32px', width: '32px', marginRight: '8px', padding: '5px' }} onClick={handleSentinel2NdviIconClick}>
                  <img src="layers.png" alt="Layer" style={{ height: '20px', width: '20px' }} />
                  {showSentinel2NDVIDot? (
                    <div
                      className="green-dot"
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        transform: 'translate(50%, 50%)',
                        width: '6.667px',
                        height: '6.667px',
                        backgroundColor: '#19D919',
                        borderRadius: '50%',
                      }}
                    />
                  ) : (
                    <div
                      className="grey-dot"
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        transform: 'translate(50%, 50%)',
                        width: '6.667px',
                        height: '6.667px',
                        backgroundColor: '#CCCCCC', // Grey color
                        borderRadius: '50%',
                      }}
                    />
                  )}
                </div>
}
                    <img
                      src="download.png"
                      alt="Download"
                      style={{ height: '20px', width: '20px' }}
                      onClick={() => handleDatasetDownloadbutton("sentinel2NDVI")}
                    />
                  </div>
                </div>
                {
                  sentinal2Loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                      <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                    </div>
                  ) : sentinal2NDVIgraph.length > 0? (
                    <div>
                      <NdviGraph
                        data={sentinal2NDVIgraph}
                        state={level1Data}
                        name={level3Data}
                      />
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" ,height:'150px',padding:'10%', border:'1px solid grey'}}>
              <p style={{ color: "black" }}>No data available</p>
                </div>
                  )}
              </div>
              :
              null}

          {
            props.modis5DayIntervalNDVIGraph ?
              <div>
                <div className='datasetgraph' style={{ display: 'flex', alignItems: 'center', padding: 16, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src="plant.png" alt="Vegetation" style={dataseticonStyle} />
                    <div style={{ top: '10px', flexDirection: 'column' }}>MODIS 10 Day Interval NDVI</div>
                  </div>
                 
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  {ndvilayeroverlayLoading ?  
                  <> <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)', position: 'relative', height: '32px', width: '32px', marginRight: '8px', padding: '5px'  }} /></>
                  : 
                  <div className="icononmap" style={{ position: 'relative', height: '32px', width: '32px', marginRight: '8px', padding: '5px' }} onClick={handleModisNdviIconClick}>
                  <img src="layers.png" alt="Layer" style={{ height: '20px', width: '20px' }} />
                  {showModisNDVIDot ? (
                    <div
                      className="green-dot"
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        transform: 'translate(50%, 50%)',
                        width: '6.667px',
                        height: '6.667px',
                        backgroundColor: '#19D919',
                        borderRadius: '50%',
                      }}
                    />

                  ) : (
                    <div
                      className="grey-dot"
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        transform: 'translate(50%, 50%)',
                        width: '6.667px',
                        height: '6.667px',
                        backgroundColor: '#CCCCCC', // Grey color
                        borderRadius: '50%',
                      }}
                    />
                  )}

                </div>}

                   
                    <img
                      src="download.png"
                      alt="Download"
                      style={{ height: '20px', width: '20px' }}
                      onClick={() => handleDatasetDownloadbutton("modisNDVI")}
                    />
                  </div>
                </div>
                {
                  modisloading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                      <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                    </div>
                  ) : modisNDVI ? (
                    <div>
                      <NdviGraph
                        data={modisNDVI}
                        state={level1Data}
                        name={level3Data}
                      />
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" ,height:'150px',padding:'10%', border:'1px solid grey'}}>
              <p style={{ color: "black" }}>No data available</p>
                </div>
                  )}
              </div>
              :
              null}

{
            props.volumetricSSM ?
              <div>
                <div className='datasetgraph' style={{ display: 'flex', alignItems: 'center', padding: 16, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src="plant.png" alt="Vegetation" style={dataseticonStyle} />
                    <div style={{ top: '10px', flexDirection: 'column' }}>Volumetric SSM</div>
                  </div>
                 
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="download.png"
                      alt="Download"
                      style={{ height: '20px', width: '20px' }}
                      onClick={() => handleDatasetDownloadbutton("volumetricSSM")}
                    />
                  </div>
                </div>
                {
                  volumetricSSMloading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                      <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                    </div>
                  ) : volumetricSSM ? (
                    <div>
                      <SSMGraph
                        data={volumetricSSM}
                        state={level1Data}
                        name={level3Data}
                      />
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" ,height:'150px',padding:'10%', border:'1px solid grey'}}>
              <p style={{ color: "black" }}>No data available</p>
                </div>
                  )}
              </div>
              :
              null}
              
        </div>
      </div>
    </div>
  );
};

export default MapDrawer;
