import { combineReducers } from "redux";
import { changeSelectedDatatypeReducer } from "./reducers/changeSelectedDatatypeReducer";
import { changeSelectedDatasetnameReducer } from "./reducers/changeSelectedDatasetnameReducer";
import { changeendDateReducer } from "./reducers/changeendDateReducer";
import { changestartDateReducer } from "./reducers/changestartDateReducer";
import { changeStateNameReducer } from "./reducers/changeStateNameReducer";
import { changeDistrictNameReducer } from "./reducers/changeDistrictNameReducer";
import { changeCountryNameReducer } from "./reducers/changeCountryNameReducer";
import { changeLevel3NameReducer } from "./reducers/changeLevel3NameReducer";
import { changeLevel4NameReducer } from "./reducers/changeLevel4NameReducer";
import { changeLevel1NameReducer } from "./reducers/changeLevel1NameReducer";
import { changeLevel2NameReducer } from "./reducers/changeLevel2NameReducer";
import { changeZoomReducer } from "./reducers/changeZoomReducer";
import { changegetlocationbysearchReducer } from "./reducers/changegetlocationbysearchReducer";
import { changeLatitudeReducer } from "./reducers/changeLatitudeReducer";
import { changeLongitudeReducer } from "./reducers/changeLongitudeReducer";
import { changeModisNDVILayerReducer } from "./reducers/changeModisNDVILayerReducer";
import { changeSentinel2NDVILayerReducer } from "./reducers/changeSentinel2NDVILayerReducer";
import { changeshowModisNDVIDotReducer } from "./reducers/changeshowModisNDVIDotReducer";
import { changeshowSentinel2NDVIDotReducer } from "./reducers/changeshowSentinelNDVIDotReducer";
import { changeproductionDateReducer } from "./reducers/changeproductionDateReducer";
import { changeRankedCropNameReducer } from "./reducers/changeRankedCropNameReducer ";
import {changeVolumetricSSMLayerReducer} from "./reducers/changeVolumetricSSMLayerReducer";
import { changeCropNameSelectedReducer } from "./reducers/changeCropNameSelectedReducer";
import { changeCropScoreSelectedReducer } from "./reducers/changeCropScoreSelectedReducer";
import { changeCropParameterFileReducer } from "./reducers/changeCropParameterFile";
import { changeFeatureDataReducer } from "./reducers/changeFeatureDataReducer";
import { changeTableAdvisoryDataReducer } from "./reducers/changeTableAdvisoryDataReducer"

const rootReducer = combineReducers({
  selecteddatatype: changeSelectedDatatypeReducer,
  selecteddataset: changeSelectedDatasetnameReducer,
  endDateSelected : changeendDateReducer,
  startDateSelected : changestartDateReducer,
  stateName: changeStateNameReducer,
  districtName: changeDistrictNameReducer,
  countryName: changeCountryNameReducer,
  level1Data: changeLevel1NameReducer,
  level2Data: changeLevel2NameReducer,
  level3Data: changeLevel3NameReducer,
  level4Data: changeLevel4NameReducer,
  zoom: changeZoomReducer,
  searchLocation: changegetlocationbysearchReducer,
  latitude: changeLatitudeReducer,
  longitude: changeLongitudeReducer,
  modisNdviLayer: changeModisNDVILayerReducer,
  sentinel2NdviLayer: changeSentinel2NDVILayerReducer,
  volumetricSSMLayer: changeVolumetricSSMLayerReducer,
  showModisNDVIDot : changeshowModisNDVIDotReducer,
  showSentinel2NDVIDot : changeshowSentinel2NDVIDotReducer,
  productionDateSelected : changeproductionDateReducer,
  rankedCropName:changeRankedCropNameReducer,
  cropNameSelected:changeCropNameSelectedReducer,
  cropScoreSelected:changeCropScoreSelectedReducer,
  cropParameterFile:changeCropParameterFileReducer,
  featureData:changeFeatureDataReducer,
  tableAdvisoryData:changeTableAdvisoryDataReducer,

});

export default rootReducer;
