import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const MonthlyLoangraph = ({ data }) => {
    const loanData = data.map((item) => ({
        name: item.month_name?.substring(0, 3) || 'N/A', // Use 'N/A' or a default value if missing
        y: item.totalLoanCount,
      }));
      

  const options = {
    chart: {
      type: 'line',
      height: 215,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: loanData.map((item) => item.name), // Use shortened month names
      labels: {
        formatter: function () { // Custom formatter for full month names on hover
          return this.value.substring(0, 3); // Show first 3 letters on hover
        },
      },
    },
    yAxis: {
      title: {
        text: 'Total Loan Count',
      },
    },
    series: [
      {
        name: 'Loans',
        data: loanData,
        color: '#95B88A',
      },
    ],
    credits: {
        enabled: false,
      },
    legend: { // Disable legend
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options}  />;
};

export default MonthlyLoangraph;
