import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

export const SSMGraph = (props) => {
  let final = [];
  if (props.data.error == "Null response") {
    return (
      <div style={{ textAlign: "center" ,height:'150px',padding:'10%',border:'1px solid grey'}}>
        <p style={{ color: "black" }}>SSM has null values</p>
      </div>
    );
  }

  if (props && props.data) {
    final = props.data
      .filter(item => item.date && item.ssm !== null && item.ssm !== undefined) // Filter out null and undefined values
      .map(item => [new Date(item.date).getTime(), item.ssm]); // Convert date to timestamp and format the data
  }

  const options = {
    chart: {
      alignTicks: false,
    },
    rangeSelector: {
      inputEnabled: false,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      opposite: false,
    },
    exporting: {
      enabled: true,
      filename: props.state + props.name + "_SSM",
    },
    tooltip: {
      formatter: function () {
        return '<b>Date: </b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '<br/><b>SSM: </b>' + Highcharts.numberFormat(this.y, 2);
      },
    },
    series: [
      {
        type: "area",
        name: "SSM",
        data: final,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          opacity: 0.2,
          stops: [
            [0, '#F5821F'], // start color
            [1, 'rgba(242, 195, 116, 1)'] // end color
          ],
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};
