import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import { changeLatitude, changeLongitude, changeLevel1Name, changeLevel2Name, changeLevel3Name, changeLevel4Name, changeStateName, changeDistrictName } from '../redux/action';
import { Icon, Style } from "ol/style";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { Feature } from "ol";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { useMap } from "./hooks/useMap";

const CoordinatesPopUp = (props) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const latitudeInputRef = useRef(null);
  const longitudeInputRef = useRef(null);
  const { map } = useMap();
  const [markerLayer, setMarkerLayer] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map) return;
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayer({
      source: markerSource,
    });
    map.addLayer(markerLayer);
    setMarkerLayer(markerLayer);

    return () => {
      map.removeLayer(markerLayer);
    };
  }, [map]);

  const goToLocation = async () => {
    markerLayer.getSource().clear();
    const marker = new Feature({
      geometry: new Point(fromLonLat([longitude, latitude])),
    });
    marker.getGeometry().transform("EPSG:3857", "EPSG:4326");
    marker.setStyle(
      new Style({
        image: new Icon({
          src: "/location-pin.png",
        }),
      })
    );
    markerLayer.getSource().addFeature(marker);

    map.getView().animate({ center: [longitude, latitude], zoom: 10, duration: 1000 });
  };

  useEffect(() => {
    if (latitudeInputRef.current) {
      latitudeInputRef.current.focus();
    }
  }, [latitudeInputRef]);

  useEffect(() => {
    if (longitudeInputRef.current) {
      longitudeInputRef.current.focus();
    }
  }, [longitudeInputRef]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleSubmit = () => {
    dispatch(changeLatitude(latitude));
    dispatch(changeLongitude(longitude));
    exploreDatasets(latitude, longitude);
  };

  const exploreDatasets = async (lat, long) => {
    const requestBody = {
      lat: lat,
      lon: long,
    };

    try {
      const response = await fetch("/api/coords/features", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const properties = data.features[0]?.properties || {};

      if (Object.keys(properties).length === 0) {
        setErrorMessage("Coordinates are outside Kenya.");
        setErrorOpen(true);
        return;
      }

      if (properties.NAME_1) {
        dispatch(changeStateName(properties.NAME_1 || ""));
        dispatch(changeLevel1Name(properties.NAME_1 || ""));
        dispatch(changeLevel2Name(""));
        dispatch(changeLevel3Name(""));
      }
      if (properties.NAME_2) {
        dispatch(changeDistrictName(properties.NAME_2 || ""));
        dispatch(changeLevel2Name(properties.NAME_2 || ""));
        dispatch(changeLevel3Name(""));
      }
      if (properties.NAME_3) {
        dispatch(changeLevel3Name(properties.NAME_3 || ""));
      }

      goToLocation();
      handleClose();
      props.removeAlllayers();
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleSubmit();
          },
        }}
      >
        <DialogTitle style={{ margin: "auto" }}>Enter Coordinates</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="float"
              className="inputArea"
              placeholder="Enter Latitude"
              style={{ marginBottom: "10px" }}
              onChange={(e) => setLatitude(parseFloat(e.target.value))}
            />
            <input
              type="float"
              className="inputArea"
              placeholder="Enter Longitude"
              style={{ marginBottom: "10px" }}
              onChange={(e) => setLongitude(parseFloat(e.target.value))}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Go to Location</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <p>{errorMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CoordinatesPopUp;
