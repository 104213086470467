import React, { useState } from "react";
import { IconButton, Tabs, Tab, Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FarmerTab from "./FarmerTab";
import ClimateRiskTab from "./ClimateRiskTab";
import MonitoringTab from "./MonitoringTab";

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ padding: "20px", fontFamily: "Dosis" }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const ViewMoreScreen = ({ farmer, onBackClick, isCollapsed }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Define the keys to display
  const displayKeys = [
    "loanId",
    "loanDuration",
    "loanAmount",
    "loanType",
    "name",
    "crop",
    "season",
  ];

  return (
    <div
      style={{
        height: "100vh",
        overflow: "scroll",
        marginBottom: "20px",
        padding: isCollapsed ? "5px" : "10px",
      }}
    >
      <div style={{ padding: "20px", background: "#EFF9FF" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            color: "white",
          }}
        >
          <IconButton
            onClick={onBackClick}
            style={{ color: "white", background: "#00253D" }}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ marginLeft: "10px", color: "#00253D" }}>Summary</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          {displayKeys.map((key) => (
            <div
              key={key}
              style={{
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                minWidth: "150px",
              }}
            >
              <strong style={{ fontSize: "14px", color: "#868682" }}>
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
                :
              </strong>
              <span style={{ fontSize: "18px" , fontWeight:700}}>{farmer[key]}</span>
            </div>
          ))}
        </div>
      </div>

      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="View More Tabs"
        textColor="primary"
        indicatorColor="primary"
        style={{ marginTop: "20px" }}
      >
        <Tab  style={{ fontWeight: 700 }} label="Farmer Details" />
        <Tab style={{ fontWeight: 700 }} label="Climate Risk Score" />
        <Tab style={{ fontWeight: 700 }} label="Monitoring" />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <Typography variant="h6">
          <FarmerTab />
        </Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Typography variant="h6">
          <ClimateRiskTab />
        </Typography>
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <Typography variant="h6">
          <MonitoringTab />
        </Typography>
      </TabPanel>
    </div>
  );
};

export default ViewMoreScreen;
