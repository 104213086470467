import React, { useState } from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const folders = [
  { name: 'January 2024', images: ['soil.png', 'crop2.png', 'crop3.png','crop4.png'] },
  { name: 'February 2024', images: ['crop2.png', 'crop3.png', 'crop4.png'] },
  { name: 'March 2024', images: ['crop2.png', 'crop3.png', 'crop4.png'] },
  { name: 'April 2024', images: ['crop2.png', 'crop3.png', 'crop4.png'] },
  { name: 'May 2024', images: ['soil.png', 'soil.png', 'soil.png'] },
];

const MonitoringTab = () => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder.name);
    setSelectedImages(folder.images);
    setPreviewImage(null); // Reset preview when a new folder is clicked
  };

  const handleImageClick = (image) => {
    setPreviewImage(image);
  };

  const handleBackClick = () => {
    setSelectedFolder(null);
    setSelectedImages([]);
    setPreviewImage(null);
  };

  return (
    <Grid container spacing={2}>
      {/* Left section */}
      <Grid item xs={8} container justifyContent="center" alignItems="center" sx={{ background: '#F1F1F1' }}>
        <Box textAlign="center">
          {previewImage ? (
            <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
          ) : (
            <>
              <ImageIcon style={{ fontSize: 80, color: '#C8C7C1' }} />
              <Typography variant="h6" color={'#C8C7C1'}>Choose an Image from folders to View</Typography>
            </>
          )}
        </Box>
      </Grid>

      {/* Right section */}
      <Grid item xs={4}>
        <Box>
          {selectedFolder ? (
            <>
              <Box display="flex" alignItems="center" mb={2}>
                <IconButton onClick={handleBackClick} style={{background:'#00253D', marginRight:'5px'}}>
                  <ArrowBackIcon style={{color:'white'}}/>
                </IconButton>
                <Typography variant="h6" style={{fontWeight:600,fontSize:'24px'}}>{selectedFolder}</Typography>
              </Box>
              <Grid container spacing={2}>
                {selectedImages.map((image, index) => (
                  <Grid item xs={12} key={index} container alignItems="center">
                    <IconButton onClick={() => handleImageClick(image)} sx={{ marginRight: 1 }}>
                      <img src={image} alt={image} style={{ width: 80, height: 'auto' }} />
                    </IconButton>
                    <Typography variant="body2">{image}</Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Grid container spacing={2}>
              {folders.map((folder) => (
                <Grid item xs={4} key={folder.name}>
                  <div
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      padding: 2,
                      borderRadius: 1,
                      boxShadow: 1,
                    }}
                  >
                    <IconButton
                      onClick={() => handleFolderClick(folder)}
                      sx={{ width: 100, height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <FolderIcon sx={{ fontSize: 100, color: '#73D7FF' }} />
                    </IconButton>
                    <Typography variant="body2" mt={1}>
                      {folder.name}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MonitoringTab;
