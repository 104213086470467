function getBimodalContent(
  productionDateSelected,
  selecteddatatype,
  selecteddataset,
  a,
  b,
  c,
  cropNameSelected,
  averagePercentage,
  cropRankingData
) {
  const today = new Date().toLocaleDateString(); // Get today's date in a readable format

  // Create the crop suitability table rows
  const suitabilityRows = cropRankingData.map(data => `
    <tr>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.cropName}</td>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.score}/100</td>
    </tr>
  `).join('');

  return `
    <div style="padding: 25px; font-size: 12px;">
      <!-- Report generation and production date -->
      <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px;">
        <div style="font-size: 12px;">Report Generation Date: ${today}</div>
        <div style="font-size: 12px;">Production Start Date: ${productionDateSelected}</div>
      </div>

      <!-- Heading -->
      <h2 style="background-color: #F5821F; color: white; padding: 10px; margin: 0; text-align: center;">
        TARA | CREDIT PRODUCTIVITY SCORE
      </h2>
      
      <!-- First Fieldset: DATASETS -->
      <fieldset style="margin-top: 15px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
        <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">DATASETS</legend>
        <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Dataset Type</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Dataset Name</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Model Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${selecteddatatype}</td>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${selecteddataset}</td>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Simple</td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      <!-- Second Fieldset: LOCATION -->
      <fieldset style="margin-top: 15px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
        <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">LOCATION</legend>
        <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Country</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">County</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Sub County</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Ward</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Kenya</td>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${a}</td>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${b}</td>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${c}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      <!-- Third Fieldset: BEST RANKED CROP -->
      <fieldset style="margin-top: 15px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
        <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">BEST RANKED CROP</legend>
        <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Crop</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Credit Productivity Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${cropNameSelected}</td>
              <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${averagePercentage}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      <!-- Fourth Fieldset: CROP SUITABILITY RANK -->
      <fieldset style="margin-top: 15px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
        <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">CROP SUITABILITY RANK</legend>
        <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Crop</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Credit Productivity Score</th>
            </tr>
          </thead>
          <tbody>
            ${suitabilityRows}
          </tbody>
        </table>
      </fieldset>
    </div>
  `;
}

export default getBimodalContent;
