import { createContext, useState } from "react";

const defaultProvider = {
  map: null,
  draw: null,
  area: null,
  geometryWKT: null,
  wmsLayerName: null,
  fishnetLayer: null,
};

export const MapContext = createContext(defaultProvider);

const MapProvider = ({ children }) => {
  const [map, setMap] = useState(defaultProvider.map);
  const [draw, setDraw] = useState(defaultProvider.draw);
  const [area, setArea] = useState(defaultProvider.area);
  const [geometryWKT, setGeometryWKT] = useState(defaultProvider.geometryWKT);
  const [wmsLayerName, setWmsLayerName] = useState(
    defaultProvider.wmsLayerName
  );
  const [fishnetLayer, setFishnetLayer] = useState(defaultProvider.fishnetLayer);

  const values = {
    map,
    draw,
    area,
    geometryWKT,
    wmsLayerName,
    fishnetLayer,
    setMap,
    setDraw,
    setArea,
    setGeometryWKT,
    setWmsLayerName,
    setFishnetLayer
  };

  return <MapContext.Provider value={values}>{children}</MapContext.Provider>;
};

export default MapProvider;
