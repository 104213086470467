import { Style, Stroke, Fill, Icon, Text } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import { MyLayers, VectorLayer } from "./Layers";
import MyMap from "./Map/MyMap";
import { vector, wms } from "./Source";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeZoom } from "../../redux/action";
import DrawOnMap from "./Draw/DrawOnMap";
import { useMap } from "../hooks/useMap";
import { toStringHDMS } from "ol/coordinate";
import VectorSource from "ol/source/Vector";
import VectorLayerOL from "ol/layer/Vector";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import TileLayer from "./Layers/TileLayer";
import { getFloodColor, getSpiColor } from "../lib/gis";



function MapViewAnalytics({ featureData, selectedState, selectedConstituency }) {
  const { map, fishnetLayer } = useMap();
  const [center, setCenter] = useState([32.290275, 1.373333]);
  //   const [data, setData] = useState();
  const [drawIsActive, setDrawIsActive] = useState(false);
  const [coords, setCoords] = useState();
  const [mapRef, setMapRef] = useState();
  const [markerLayer, setMarkerLayer] = useState(null);
  const dispatch = useDispatch();
  const zoom = useSelector((state) => state.zoom);


  useEffect(() => {
    if (!map) return;
    dispatch(changeZoom(5));
    map.on("pointermove", function (event) {
      if (event.coordinate) {
        const coordinates = toStringHDMS(event.coordinate);
        setCoords(coordinates);
      } else {
        setCoords();
      }
    });

    map.on("singleclick", function (event) {
      let fishnetWmslayerVar = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "fishnetWms");

      let url = null;
      if (fishnetWmslayerVar) {
        const viewResolution = map.getView().getResolution();
        url = fishnetWmslayerVar
          .getSource()
          .getFeatureInfoUrl(event.coordinate, viewResolution, "EPSG:4326", {
            INFO_FORMAT: "application/json",
          });
      }
      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            map.removeLayer(
              map
                .getLayers()
                .getArray()
                .find((layer) => layer.get("name") === "highlightLayer")
            );
            if (data.features.length !== 0) {
              let feature = data.features[0].properties;
              if (feature) {
                let geoJSon = data.features[0];
                let PixelID = feature.PixelID;
                let PixelNames = feature.PixelNames;
                //console.log("PixelID", PixelID);
                //console.log("PixelNames", PixelNames);
                let highlightFeature = new GeoJSON().readFeature(geoJSon);
                let highlightLayer = new VectorLayerOL({
                  source: new VectorSource({
                    features: [highlightFeature],
                  }),
                  zIndex: 90,
                  style: new Style({
                    stroke: new Stroke({
                      color: "rgba(0, 255, 0, 1)",
                    }),
                    fill: new Fill({
                      color: "rgba(0, 255, 0, 0.3)",
                    }),
                  }),
                });
                map.addLayer(highlightLayer);
                highlightLayer.set("name", "highlightLayer");
              }
            }
          });
      }
    });

    // Initialize marker layer
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayerOL({
      source: markerSource,
    });
    map.addLayer(markerLayer);
    setMarkerLayer(markerLayer);

    return () => {
      map.removeLayer(markerLayer);
    };
  }, [map]);


  const handleZoomIn = () => {
    // Implement the logic for zooming in or out
    dispatch(changeZoom(zoom + 1));
  };

  const handleZoomOut = () => {
    // Implement the logic for zooming in or out
    dispatch(changeZoom(zoom - 1));
  };

  const handleCurrentLocation = () => {
    // Implement the logic for handling current location
    navigator.geolocation.getCurrentPosition(
      // Success callback
      (position) => {
        const { latitude, longitude } = position.coords;
        // Clear previous markers
        markerLayer.getSource().clear();
        // Add new marker
        const marker = new Feature({
          geometry: new Point(fromLonLat([longitude, latitude])),
        });
        marker.getGeometry().transform("EPSG:3857", "EPSG:4326");
        marker.setStyle(
          new Style({
            image: new Icon({
              src: "/location-pin.png",
            }),
          })
        );
        markerLayer.getSource().addFeature(marker);
        map
          .getView()
          .animate({ center: [longitude, latitude], zoom: 12, duration: 1000 });
        // Set map center to current location
        // map.current.setCenter({ lat: latitude, lng: longitude });
      }
    );
  };

  const handlePictureFrame = async () => {
    // Draw the map to the canvas
    map.once("rendercomplete", function () {
      map
        .getViewport()
        .querySelector("canvas")
        .toBlob(function (blob) {
          // Create download link
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";

          // Create object URL
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "map_snapshot.png";
          a.click();

          // Cleanup
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        });
    });

    map.renderSync();
  };

  const handleShare = () => {
    // Implement the logic for handling share icon
  };

  const toggleDrawing = () => {
    setDrawIsActive(!drawIsActive);
  };



  return (
    <>
      {coords && (
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            background: "#fff",
            padding: "5px 10px",
            margin: "10px",
            borderRadius: "4px",
          }}
        >
          {coords}
        </div>
      )}
      <MyMap zoom={zoom} center={center} getMapRef={setMapRef}>
        
        <MyLayers>
          <DrawOnMap type="Polygon" isActive={drawIsActive} />
          {featureData !== "" && featureData &&
            featureData.features.length > 0
            ? (
              <VectorLayer
                zoomTo
                data={featureData}
                source={vector({
                  features: new GeoJSON()
                    .readFeatures(featureData)
                    .filter((x) => x.getGeometry() != null),
                })}
                style={(feature) => {
                  if (selectedConstituency == "")
                    return getSpiColor(feature, selectedState);
                  else
                    return getSpiColor(feature, selectedConstituency);
                }}
                name="AdministrativeBoundary"
                zIndex={10}
              />
            ) : ('')}
          <VectorLayer
            source={vector({
              features: new GeoJSON().readFeatures({
                type: "FeatureCollection",
                features: [],
              }),
            })}
            style={
              new Style({
                stroke: new Stroke({
                  color: "yellow",
                  width: 3,
                }),
                fill: new Fill({
                  color: "rgba(0, 0, 255, 0.1)",
                }),
              })
            }
            name="region"
          />

          {fishnetLayer !== null && (
            <TileLayer
              name="fishnetWms"
              source={wms({
                url: "http://65.21.126.12:8090/geoserver/agtull/wms",
                layer: `agtull:${fishnetLayer.layerName}`,
                filter: `BBOX(geom, ${fishnetLayer.wktGeom})`,
              })}
              zIndex={2}
            />
          )}
        </MyLayers>
      </MyMap>
      <div
        className="mapicons"
        style={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <div className="zoomingicon">
          <img src="plus.png" alt="Download icon" onClick={handleZoomIn} />
          <div
            style={{
              width: "14px",
              height: "2.067px",
              transform: "rotate(-90deg)",
              border: "1px solid #E4E4E2",
            }}
          ></div>
          <img src="minus.png" alt="Download icon" onClick={handleZoomOut} />
        </div>

        <div className="iconbox" onClick={handleCurrentLocation}>
          <img src="loc-current.png" alt="Download icon" />
        </div>

        <div
          className="iconbox"
          onClick={toggleDrawing}
          style={{ background: drawIsActive ? "#f76808" : "#fff" }}
        >
          <img src="mapiconpng.png" alt="Download icon" />
        </div>

        <div className="iconbox" onClick={handlePictureFrame}>
          <img src="picture-frame.png" alt="Download icon" />
        </div>

        <div className="iconbox" onClick={handleShare}>
          <img src="share.png" alt="Download icon" />
        </div>
      </div>
    </>
  );
}

export default MapViewAnalytics;
