const awsmobile = {
  "aws_project_region": "ap-south-1",
  "aws_cognito_identity_pool_id": "ap-south-1:5b6e3a0c-fbc3-4e61-948e-51c0aec34785",
  "aws_cognito_region": "ap-south-1",
  "aws_user_pools_id": "ap-south-1_1842zlayd",
  "aws_user_pools_web_client_id": "2llh30qf9sdbeuq6ujrl3pqvj",
  "oauth": {},
  // "aws_mobile_analytics_app_region": "ap-south-1",
  // "aws_mobile_analytics_app_id": "9b7ffa0f54f74ef7856b33d0daa48014"
};
export default awsmobile;