import { useEffect } from "react";
import { Draw, Modify, Snap } from "ol/interaction";
import OLVectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";
import WKT from "ol/format/WKT";
import Overlay from "ol/Overlay";
import { getArea } from "ol/sphere";
import { useMap } from "../../hooks/useMap";

function DrawOnMap({ type, isActive }) {
  const {
    map,
    area: totalArea,
    draw,
    setDraw,
    setArea,
    setGeometryWKT,
  } = useMap();
  const source = new VectorSource({ wrapX: false });

  useEffect(() => {
    if (!map) return;
    if (totalArea) {
      setArea(null);
    }

    let wktFormat = new WKT({
      splitcollection: true,
    });
    if (type !== "") {
      const modify = new Modify({ source: source });
      let snap = new Snap({ source: source });
      let vectorLayer = new OLVectorLayer({
        source,
      });
      vectorLayer.set("name", "drawLayer");
      let drawObj = new Draw({
        source: source,
        type: type,
      });
      setDraw(drawObj);
      if (isActive) {
        const calculateArea = function (polygon) {
          const cloned = polygon.clone();
          cloned.transform("EPSG:32643", "EPSG:3857");
          const area = getArea(cloned);
          return area;
        };

        const formatArea = function (area) {
          let output = area * 0.000247105; //convert to acres
          return output.toFixed(3) + " Acres";
        };

        /**
         * The measure tooltip element.
         * @type {HTMLElement}
         */
        let measureTooltipElement;

        /**
         * Overlay to show the measurement.
         * @type {Overlay}
         */
        let measureTooltip;

        /**
         * Creates a new measure tooltip
         */
        function createMeasureTooltip() {
          if (measureTooltipElement) {
            measureTooltipElement.parentNode.removeChild(measureTooltipElement);
          }
          measureTooltipElement = document.createElement("div");
          measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
          measureTooltip = new Overlay({
            element: measureTooltipElement,
            offset: [0, -15],
            positioning: "bottom-center",
            stopEvent: false,
            insertFirst: false,
          });
          map.addOverlay(measureTooltip);
        }

        map.addInteraction(modify);
        map.addInteraction(drawObj);
        createMeasureTooltip();

        map.addInteraction(snap);
        map.addLayer(vectorLayer);
      } else {
        map.removeInteraction(draw);
        map.removeInteraction(modify);
        map.removeInteraction(snap);
      }
    }
  }, [map, type, isActive]);

  return null;
}

export default DrawOnMap;
