export function changeSelectedDatatype(data) {
  return {
    type: "CHANGE_Selecteddatatype",
    payload: { selecteddatatype: data },
  };
}
export function changeSelectedDatasetname(data) {
  return {
    type: "CHANGE_Selecteddataset",
    payload: { selecteddataset: data },
  };
}
export function changestartDate(data) {
  return {
    type: "CHANGE_START_DATE",
    payload: { startDateSelected: data },
  };
}
export function changeendDate(data) {
  return {
    type: "CHANGE_END_DATE",
    payload: { endDateSelected: data },
  };
}
export function changeproductionDate(data) {
  return {
    type: "CHANGE_PRODUCTION_DATE",
    payload: { productionDateSelected: data },
  };
}

export function changeCountryName(data) {
  return {
    type: "CHANGE_COUNTRY_NAME",
    payload: { countryName: data },
  };
}
export function changeStateName(data) {
  return {
    type: "CHANGE_STATE_NAME",
    payload: { stateName: data },
  };
}
export function changeRankedCropName(data) {
  return {
    type: "CHANGE_RankedCropName",
    payload: { rankedCropName: data },
  };
}
export function changeCropNameSelected(data) {
  return {
    type: "CHANGE_CropNameSelected",
    payload: {cropNameSelected: data },
  };
}
export function changeCropScoreSelected(data) {
  return {
    type: "CHANGE_CropScoreSelected",
    payload: {cropScoreSelected: data },
  };
}





export function changeDistrictName(data) {
  return {
    type: "CHANGE_DISTRICT_NAME",
    payload: { districtName: data },
  };
}
export function changeLevel1Name(data) {
  return {
    type: "CHANGE_LEVEL1DATA_NAME",
    payload: { level1Data: data },
  };
}
export function changeLevel2Name(data) {
  return {
    type: "CHANGE_LEVEL2DATA_NAME",
    payload: { level2Data: data },
  };
}
export function changeLevel3Name(data) {
  return {
    type: "CHANGE_LEVEL3DATA_NAME",
    payload: { level3Data: data },
  };
}
export function changeLevel4Name(data) {
  return {
    type: "CHANGE_LEVEL4DATA_NAME",
    payload: { level4Data: data },
  };
}

export function changeZoom(data) {
  return {
    type: "CHANGE_ZOOM",
    payload: { zoom: data },
  }
}

export function changegetlocationbysearch(data) {
  return {
    type: "CHANGE_GET_LOC",
    payload: { searchLocation: data },
  }
}

export function changeLatitude(data){
  return {
    type: "CHANGE_LATITUDE",
    payload: { latitude: data },
  }
}

export function changeLongitude(data){
  return {
    type: "CHANGE_LONGITUDE",
    payload: { longitude: data },
  }
}
export function changeModisNDVILayer(data) {
  return {
    type: "CHANGE_MODIS_NDVI_LAYER",
    payload: { modisNdviLayer: data },
  }
}

export function changeSentinel2NDVILayer(data) {
  return {
    type: "CHANGE_SENTINEL2_NDVI_LAYER",
    payload: { sentinel2NdviLayer: data },
  }
}

export function changeVolumetricSSMLayer(data) {
  return {
    type: "CHANGE_VOLUMETRIC_SSM_LAYER",
    payload: { volumetriSSMLayer: data },
  }
}

export function changeshowModisNDVIDot(data) {
  return {
    type: "CHANGE_showModisNDVIDot",
    payload: { showModisNDVIDot: data },
  }
}

export function changeshowSentinelNDVIDot(data) {
  return {
    type: "CHANGE_showSentinel2NDVIDot",
    payload: { showSentinel2NDVIDot: data },
  }
}

export function changeCropParameterFile(data) {
  return {
    type: "CHANGE_CROP_PARAMETER_FILE",
    payload: { cropParameterFile: data },
  }
}

export function changeFeatureData(data) {
  return {
    type: "CHANGE_FEATURE_DATA",
    payload: { featureData: data },
  }
}

export function changeTableAdvisoryData(data) {
  return {
    type: "CHANGE_TABLE_ADVISORY_DATA",
    payload: { tableAdvisoryData: data },
  }
}
