import React,{useState} from "react";
import { Box, Card, Typography, Grid } from "@mui/material";
import RainfallAreaRangeGraph from "./RainfallAreaRangeGraph";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const ClimateRiskTab = () => {
  const [selectedValue, setSelectedValue] = useState('Rainfall');

  const dummyData = [
    [30, 70],
    [20, 60],
    [25, 50],
    [15, 40],
    [20, 45],
    [10, 35],
    [5, 30],
    [10, 35],
    [15, 50],
    [20, 60],
    [25, 65],
    [30, 70],
  ];

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "30px",
        }}
      >
        <Grid container spacing={1}>
          {/* First Card */}
          <Grid item xs={12} md={6} lg={3}>
            <Card
              sx={{
                width: "20vw",
                height: "104px",
                borderRadius: "8px",
                padding: "16px 24px",
                backgroundColor: "#FBFDFF",
                border: "0.4px solid #000",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "20px",
                  color: "#706F6C",
                }}
              >
                Score During Loan Sanction
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "32px", color: "#026B13" }}
              >
                32 %
              </Typography>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={6} lg={3}>
            <Card
              sx={{
                width: "20vw",
                height: "104px",
                borderRadius: "8px",
                padding: "16px 24px",
                backgroundColor: "#FBFDFF",
                border: "0.4px solid #000",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "20px",
                  color: "#706F6C",
                }}
              >
                Current Score
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "32px", color: "red" }}
              >
                21 %
              </Typography>
            </Card>
          </Grid>

          {/* Third Card */}
          <Grid item xs={12} md={6} lg={3}>
            <Card
              sx={{
                width: "20vw",
                height: "104px",
                borderRadius: "8px",
                padding: "16px 24px",
                backgroundColor: "#FBFDFF",
                border: "0.4px solid #000",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#706F6C",
                }}
              >
                Forecasted Score During Loan Repayment
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "32px", color: "#FFB727" }}
              >
                43 %
              </Typography>
            </Card>
          </Grid>

          {/* Fourth Card - Legend with Horizontal Bar */}
          <Grid item xs={12} md={6} lg={3}>
  <Card
    sx={{
      width: "22vw",
      height: "104px", // Increased height to accommodate additional text
      borderRadius: "8px",
      padding: "10px 16px",
      backgroundColor: "#FBFDFF",
      border: "0.4px solid #000",
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: 500,
        fontSize: "18px",
        color: "#706F6C",
      }}
    >
      Legend
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <Box sx={{ width: "400px", height: "20px", display: "flex", position: "relative" }}>
        <Box
          sx={{
            width: "70px",
            height: "100%",
            backgroundColor: "red",
            position: "relative",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              color: "#FFFFFF",
            }}
          >
            0-40
          </Typography>
        </Box>
        <Box
          sx={{
            width: "70px",
            height: "100%",
            backgroundColor: "#FFB727",
            position: "relative",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              color: "#FFFFFF",
            }}
          >
            41-60
          </Typography>
        </Box>
        <Box
          sx={{
            width: "70px",
            height: "100%",
            backgroundColor: "#01B827",
            position: "relative",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              color: "#FFFFFF",
            }}
          >
            61-90
          </Typography>
        </Box>
        <Box
          sx={{
            width: "70px",
            height: "100%",
            backgroundColor: "#026B13",
            position: "relative",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              color: "#FFFFFF",
            }}
          >
            91-100
          </Typography>
        </Box>
      </Box>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          Germination
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          0%
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          Vegetative
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          30%
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          Flowering
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          5%
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          Pre-Harvest
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          30%
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          Full Season
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>
          30%
        </Typography>
      </Box>
    </Box>
  </Card>
</Grid>

        </Grid>
      </Box>
      <div sx={{ fontWeight: 500, fontSize: "18px", lineHeight: "20px" ,}}>
        Rainfall Data (Loan Duration)
      </div>
      <FormControl sx={{ marginTop: "15px", width: "360px", height: "36px", marginBottom:'15px' }}>
        <InputLabel>Data Type</InputLabel>
        <Select
          value={selectedValue}
          onChange={handleSelectChange}
          label="Data Type"
          sx={{
            width: "360px",
            height: "36px",
            lineHeight: "36px",
          }}
        >
          <MenuItem value="Rainfall">Rainfall</MenuItem>
          <MenuItem value="Soil Moisture">Soil Moisture</MenuItem>
          <MenuItem value="Vegetation">Vegetation</MenuItem>
        </Select>
      </FormControl>
      <RainfallAreaRangeGraph data={dummyData} />
    </div>
  );
};

export default ClimateRiskTab;
