import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

const PieChart = ({ data }) => {
  useEffect(() => {
    const chartData = Object.entries(data).map(([name, value]) => ({
      name,
      y: value,
    }));

    const colors = ['#FF9800', 'rgba(22, 93, 255, 1)', '#00E396', 'rgba(20, 201, 201, 1)'];

    Highcharts.chart('pie-chart-container', {
      chart: {
        type: 'pie',
      },
      title: {
        text: 'Loan Distribution by Crop',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          innerSize: '50%', // Convert pie to donut
          colors: colors,
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}', // Show both name and value in the chart labels
          },
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        labelFormatter: function() {
          return `${this.y}`; // Show only the value in the legend
        },
      },
      series: [
        {
          name: 'Total Loan Amount',
          data: chartData,
        },
      ],
    });
  }, [data]);

  return <div id="pie-chart-container" style={{ width: '100%', height: '265px' }} />;
};

export default PieChart;
