import TileWMS from "ol/source/TileWMS";

function wms(props) {
  return new TileWMS({
    url: props.url,
    params: {
      LAYERS: props.layer,
      TILED: true,
      FORMAT: "image/png",
      CQL_FILTER: props.filter,
    },
    serverType: "geoserver",
    crossOrigin: "anonymous",
    // Countries have transparency, so do not fade tiles:
    transition: 0,
  });
}

export default wms;
