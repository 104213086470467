import React from 'react';
import '../mainScreen.css';

const ActivitiesTables = ({ activities }) => {
  return (
    <div className="activities-tables">
      {activities.map((activity, index) => (
        <div key={index} className="activity-table">
        <div style={{ backgroundColor: '#F2F2F2',color:'black', padding: '10px', color: 'black', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', fontSize:'24px', fontWeight:500 , width:'250px'}}>
         {activity.activity}</div>
          <table>
            <tbody>
              <tr>
                <th>Description:</th>
                <td>{activity.description}</td>
              </tr>
              <tr>
                <th>Expected Status:</th>
                <td>{activity.expected_progress_status}</td>
              </tr>
              <tr>
                <th>Timeline:</th>
                <td> Start : {`${activity.timeline.start} End : ${activity.timeline.end}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default ActivitiesTables;
