import React, { useState } from 'react';
import { IoInformationCircleOutline } from "react-icons/io5";

const RiskColorTable = (props) => {
  const { averagePercentage } = props;
  // const [showTable, setShowTable] = useState(false);

  const getEligibilityAdvice = () => {
    if (averagePercentage >= 0 && averagePercentage <= 40) {
      return "Crop has extremely high production risk and not advisable for loan disbursement";
    } else if (averagePercentage >= 41 && averagePercentage <= 65) {
      return "Crop has high production risk and loan should be disbursed with mandatory insurance coverage";
    } else if (averagePercentage >= 66 && averagePercentage <= 90) {
      return "Crop has moderate production risk and loan can be disbursed with optional insurance coverage";
    } else if (averagePercentage >= 91 && averagePercentage <= 100) {
      return "Crop has minimum production risk and loan can be disbursed with optional insurance coverage";
    } else {
      return "";
    }
  };

  // const handleMouseEnter = () => {
  //   setShowTable(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowTable(false);
  // };

  // const handleClick = () => {
  //   setShowTable((prevShowTable) => !prevShowTable);
  // };

  return (
    <>
      <div style={{ height: '20px', width: '90%', borderRadius: '5px', position: 'relative', alignItems: 'center', marginBottom: '50px' }}>
        <div style={{ position: 'absolute', top: 0, left: '5%', height: '100%', width: '25%', background: '#FF0000', borderRadius: '5px 0 0 5px' }}></div>
        <div style={{ position: 'absolute', top: 0, left: '30%', height: '100%', width: '25%', background: '#FEB703' }}></div>
        <div style={{ position: 'absolute', top: 0, left: '55%', height: '100%', width: '25%', background: '#02B801' }}></div>
        <div style={{ position: 'absolute', top: 0, left: '80%', height: '100%', width: '25%', background: '#026B01', borderRadius: '0 5px 5px 0' }}></div>
        <div style={{ position: 'absolute', left: '10%', top: '100%', fontSize: '12px', color: '#000' }}>0-40</div>
        <div style={{ position: 'absolute', left: '40%', top: '100%', fontSize: '12px', color: '#000' }}>41-65</div>
        <div style={{ position: 'absolute', left: '65%', top: '100%', fontSize: '12px', color: '#000' }}>66-90</div>
        <div style={{ position: 'absolute', left: '90%', top: '100%', fontSize: '12px', color: '#000' }}>91-100</div>
      </div>
      <hr className="horizontal-rule" />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'center' }}>
        <div style={{ fontSize: '15px', color: '#000', textAlign: 'center' , fontWeight:'bold',padding:'20px'}}>
          {getEligibilityAdvice()}
        </div>
        {/* <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
          <IoInformationCircleOutline size={20} />
        </div> */}
      </div>
      {/* {showTable && (
        <table style={{ fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px', background: 'rgba(217, 217, 217, 0.30)', width: '100%' }}>
          <thead style={{ borderRadius: '5px 0px 0px 0px', background: '#91B187', color: '#FFF', padding: '20px' }}>
            <tr style={{ padding: '20px' }}>
              <th>Loan Risk</th>
              <th>Loan Eligibility & Advice</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ padding: '10px' }}>
              <td style={{ color: '#000', padding: '10px' }}>
                <span style={{ color: 'red', marginRight: '5px' }}>&#9679;</span>
                Very High
              </td>
              <td style={{ color: '#000' }}>Insert Eligibility & Advice for Very High Risk</td>
            </tr>
            <tr>
              <td style={{ color: '#000', padding: '10px' }}>
                <span style={{ color: '#FEB703', marginRight: '5px' }}>&#9679;</span>
                High
              </td>
              <td style={{ color: '#000', padding: '10px' }}>Insert Eligibility & Advice for Low Risk</td>
            </tr>
            <tr>
              <td style={{ color: '#000', padding: '10px' }}>
                <span style={{ color: '#02B801', marginRight: '5px' }}>&#9679;</span>
                Low
              </td>
              <td style={{ color: '#000', padding: '10px' }}>Insert Eligibility & Advice for Moderate Risk</td>
            </tr>
            <tr>
              <td style={{ color: '#000', padding: '10px' }}>
                <span style={{ color: '#026B01', marginRight: '5px' }}>&#9679;</span>
                Very Low
              </td>
              <td style={{ color: '#000', padding: '10px' }}>Insert Eligibility & Advice for High Risk</td>
            </tr>
          </tbody>
        </table>
      )} */}
    </>
  );
};

export default RiskColorTable;
