import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const FarmerTab = () => {
  const loanDetails = [
    {
      dateRange: '15/03/24 - 15/10/24',
      loanType: 'Inputs-Fertilizer and Seeds',
      loanAmount: '$5,600.00',
      acreage: '4.5 acres',
      cropName: 'Maize',
      cropVariety: 'SC Duma 43',
      insured: 'Yes',
      season: 'Long Rains',
      repayment: 'Yes',
    },
    {
      dateRange: '01/03/24 - 20/09/25',
      loanType: 'Inputs-Fertilizer and Seeds',
      loanAmount: '$2,300.00',
      acreage: '5 acres',
      cropName: 'Greengrams',
      cropVariety: 'KK Mbaazi 1',
      insured: 'No',
      season: 'Short Rains',
      repayment: 'Yes',
    },
    {
      dateRange: '22/01/24 - 22/06/26',
      loanType: 'Inputs-Fertilizer and Seeds',
      loanAmount: '$3,000.00',
      acreage: '5.2 acres',
      cropName: 'Avocado',
      cropVariety: 'Hayes',
      insured: 'Yes',
      season: 'Long Rains',
      repayment: 'Yes',
    },
    {
      dateRange: '06/02/24 - 15/08/27',
      loanType: 'Inputs-Fertilizer and Seeds',
      loanAmount: '$1,800.00',
      acreage: '4.5 acres',
      cropName: 'Potatoes',
      cropVariety: 'Kenya Mpya',
      insured: 'Yes',
      season: 'Short Rains',
      repayment: 'Yes',
    },
    {
      dateRange: '25/03/24 - 15/10/28',
      loanType: 'Inputs-Fertilizer and Seeds',
      loanAmount: '$5,500.00',
      acreage: '4 acres',
      cropName: 'Beans',
      cropVariety: 'MWR 121',
      insured: 'Yes',
      season: 'Long Rains',
      repayment: 'No',
    },
    {
      dateRange: '19/02/24 - 15/09/29',
      loanType: 'Inputs-Fertilizer and Seeds',
      loanAmount: '$2,000.00',
      acreage: '3.6 acres',
      cropName: 'Maize',
      cropVariety: 'Pannar 691',
      insured: 'No',
      season: 'Long Rains',
      repayment: 'No',
    },
    {
      dateRange: '15/03/24 - 10/08/30',
      loanType: 'Inputs-Fertilizer and Seeds',
      loanAmount: '$3,800.00',
      acreage: '6 acres',
      cropName: 'Maize',
      cropVariety: 'SC Duma 43',
      insured: 'Yes',
      season: 'Long Rains',
      repayment: 'Yes',
    },
  ];

  return (
    <div>
      {/* Farmer Details Paper */}
      <Paper
        sx={{
          height: '88px',
          padding: '16px 32px',
          borderRadius: '8px 0px 0px 0px',
          border: '0.4px solid transparent',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#FBFDFF',
          opacity: 1,
          marginBottom: '20px',
        }}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Full Name
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
          Otieno Odhiambo

          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Educational Level
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
           Secondary
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Gender
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            Male
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Age
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            35
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Contact Number
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            +1234567890
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Location
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
          Kenya, Trans Nzoia, Kipkeikei
          </Typography>
        </Box>
        
      </Paper>

      <Typography variant="h6" sx={{ marginBottom: '16px' }}>Past Loans</Typography>

      {/* Past Loans Cards */}
      {loanDetails.map((loan, index) => (
        <Paper
          key={index}
          sx={{
            padding: '16px 32px',
            borderRadius: '8px',
            border: '1px solid lightgrey',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '16px',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Start Date - End Date
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.dateRange}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Loan Type
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.loanType}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Loan Amount
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.loanAmount}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Acreage
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.acreage}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Crop Name
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.cropName}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Crop Variety
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.cropVariety}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Insured
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.insured}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Season
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.season}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Repayment
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.repayment}
            </Typography>
          </Box>
        </Paper>
      ))}
    </div>
  );
};

export default FarmerTab;
