import React, { useState, useEffect } from 'react';
import '../mainScreen.css';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedDatasetname, changeSelectedDatatype, changeendDate, changestartDate } from '../redux/action';
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAnglesRight } from "react-icons/fa6";



const LeftPanelExplore = (props) => {
  const [age, setAge] = React.useState('');
  const [datatype, setdatatype] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const selecteddatatype = useSelector((state) => state.selecteddatatype);
  const selecteddataset = useSelector((state) => state.selecteddataset);
  const startDateSelected = useSelector((state) => state.startDateSelected);
  const productionDateSelected = useSelector((state) => state.productionDateSelected);
  const endDateSelected = useSelector((state) => state.endDateSelected);
  const [openCoordinatePopup,setOpenCoordinatePopup] = useState(false);
  const [clearMarkers,setClearMarkers] = useState(false)
  const [clickedButton, setClickedButton] = useState('map');
  const a = useSelector((state) => state.level1Data)
  const b = useSelector((state) => state.level2Data)
  const c = useSelector((state) => state.level3Data)
  const [startDate,setStartDate] = useState('2004-01-01')
  const [endDate,setEndDate] = useState('2023-12-31')
  const [isCollapsed, setIsCollapsed] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {

    async function fetchData() {
      try {
        const datatypeResponse = await fetch(`/api/homepage/datatype`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (datatypeResponse.ok) {
          const datatypedata = await datatypeResponse.json();
          setdatatype(datatypedata);
        } else {
          console.error('Failed to fetch data types');
        }
      } catch (error) {
        console.error('Error fetching data types:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDataset() {
      try {
        if (selecteddatatype !== '') {
          const datasetResponse = await fetch(`/api/homepage/datasetname?datatype=${selecteddatatype}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (datasetResponse.ok) {
            const datasetdata = await datasetResponse.json();
            setDataset(datasetdata);
          } else {
            console.error('Failed to fetch datasets');
          }
        }
      } catch (error) {
        console.error('Error fetching datasets:', error);
      }
    }
    fetchDataset();
  }, [selecteddatatype]);


  useEffect(() => {
    if (selecteddatatype === "Rainfall") {
      if (selecteddatatype && selecteddataset && productionDateSelected && age) {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
    } else if (selecteddatatype === "Vegetation" || selecteddatatype === "Soil Moisture") {
      if (selecteddatatype && selecteddataset ) {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
    } else {
      // Handle other cases if needed
      setSubmitDisabled(true);
    }
  }, [selecteddatatype, selecteddataset, productionDateSelected, startDate,endDate, age]);
  
  const handleSubmit = () => {
    if (!a || !b || !c) {
      alert("Please select all three levels from map");
      return;
    }
    if(selecteddatatype == 'Rainfall')
    {
    props.setMainscreen(false);
    props.setriskscreen(true);
    }
    else
    {
      props.setGraph(selecteddataset)
    }
  };
  const handleButtonClick = (button) => {
    setClickedButton(button);
    // alert("Feature Coming soon")
};

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // Process the uploaded file
    console.log("Uploaded file:", file);
  };

  const handleChange = (event) => {
    dispatch(changeSelectedDatatype(event.target.value));
    dispatch(changeSelectedDatasetname(null));
    setAge('')
  };

  const handlefuncttionChange = (event) => {
    setAge(event.target.value);
  };

  const handledatasetnameChange = (event) => {
    dispatch(changeSelectedDatasetname(event.target.value));
  };

  const handleSetOpen = (e) => {
    setOpenCoordinatePopup(e)
  }

  const handleMapClick = () => {
    props.loadMap();
    handleButtonClick('map');
    setClearMarkers(!clearMarkers)
  }



  return (
    <div className={`left-panel ${isCollapsed ? 'collapsed' : ''}`}>
    <div className="collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
       {isCollapsed ? <FaAnglesRight /> : <FaAnglesLeft />}
     </div>
     {!isCollapsed && (
        <>
      <div className="heading" style={{ color: '#000', fontSize: '3.5vh', fontStyle: 'normal', fontWeight: 900, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '15px' }}>
        PARAMETERS
      </div>
      <div className="subheading" style={{ color: '#000', fontSize: '14px', fontWeight: 500, lineHeight: '2vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '5px' }}>
        Set Your Region of interest
      </div>
      <div className="custom-bar">
        <div className={`custom-buttontop ${clickedButton === 'map' ? 'clicked' : ''}`} style={{ marginRight: '10px',width:'30%' }} onClick={handleMapClick}>Map</div>

      </div>
      
     
      <div className="dataset-heading" style={{ color: '#000', fontSize: '13px', fontWeight: 500, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', fontFeatureSettings: "'clig' off, 'liga' off", marginBottom: '0.7vh' }}>
        DATA SETS
      </div>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Data type</InputLabel>
        <Select
          style={{ height: clickedButton === 'upload' ? '5.5vh' : '6vh', marginBottom: '2vh', fontSize: '14px', alignItems: 'center' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selecteddatatype}
          label="Data type"
          onChange={handleChange}
        >
          {datatype.map((item, index) => (
            <MenuItem style={{ fontSize: '14px' }} key={index} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Dataset Name</InputLabel>
        <Select
          style={{height: clickedButton === 'upload' ? '5.5vh' : '6vh', marginBottom: '1vh', fontSize: '14px' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selecteddataset}
          label="Dataset Name"
          onChange={handledatasetnameChange}
        >
          {dataset.length > 0 ? (
            dataset.map((item, index) => (
              <MenuItem style={{ fontSize: '14px' }} key={index} value={item}>{item}</MenuItem>
            ))
          ) : null}
        </Select>
      </FormControl>

     
      <div className="dataset-heading" style={{ color: '#000', fontSize: '15px', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.46px',fontFeatureSettings: "'clig' off, 'liga' off", marginBottom: '10px' }}>
        Time series start and end date
      </div>

      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <div style={{ marginRight: '10px', color: 'black', }}>
          <TextField
            type="date"
            value={
              selecteddatatype === 'Vegetation'
                ? startDate
                : selecteddatatype === 'Soil Moisture'
                ? startDate
                : startDateSelected // Default to startDateSelected for other cases
            }
            label="Start Date"
            onChange={(e) => {
              setStartDate(e.target.value)
              dispatch(changestartDate(e.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            disabled={selecteddatatype === 'Rainfall'}
          />
        </div>
        <div style={{ marginRight: '10px', color: 'black' }}>
          <TextField
            type="date"
            label="End Date"
            value={
              selecteddatatype === 'Vegetation'
                ? endDate
                : selecteddatatype === 'Soil Moisture'
                ? endDate
                : endDateSelected
                 // Default to startDateSelected for other cases
            }
            onChange={(e) => {
              setEndDate(e.target.value)
              dispatch(changeendDate(e.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            style={{ height: '10px' }}
            disabled={selecteddatatype === 'Rainfall'}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="custom-button" style={{ width: '50%', backgroundColor: submitDisabled ? '#ccc' :'#00253D'}}
        onClick={handleSubmit}
        disabled={submitDisabled}
        >
          Submit
        </div>
        <div className="custom-button" style={{ width: '50%', display: 'flex', flexDirection: 'row' }} onClick={props.handleClearSelection}>
        Clear Selection
        </div>
      </div>
      </>
      )}
    </div>
  );
};

export default LeftPanelExplore;
